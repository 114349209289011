var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Import-tool NAM")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "fileUpload__container",
                            attrs: { "fill-height": "", "pa-1": "", wrap: "" }
                          },
                          [
                            _c("MiFileUpload", {
                              ref: "fileUpload",
                              attrs: {
                                filePath: _vm.uploadedFile,
                                clearAfterUpload: true,
                                multiple: false,
                                acceptedFileTypes: _vm.acceptedFileTypes
                              },
                              on: {
                                itemDropped: function($event) {
                                  return _vm.handleItemDropped($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.fileUploaded
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "elementPanel elementPanel--spaced fill-height"
                  },
                  [
                    _c(
                      "v-layout",
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          true
                            ? _c(
                                "table",
                                {
                                  staticClass: "item__list item__list--table",
                                  attrs: { wrap: "" }
                                },
                                [
                                  _c("thead", [
                                    _c("tr", { staticClass: "label" }, [
                                      _c("td", [
                                        _c("span", [_vm._v("Status")])
                                      ]),
                                      _c("td", [_c("span", [_vm._v("Aantal")])])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.fileResults, function(
                                      fileResult,
                                      index
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          staticClass:
                                            "list__item list__item--white"
                                        },
                                        [
                                          _c("td", [
                                            _c(
                                              "span",
                                              { staticClass: "item--bold" },
                                              [_vm._v(_vm._s(fileResult.label))]
                                            )
                                          ]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(_vm._s(fileResult.value))
                                            ])
                                          ])
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.fileUploaded
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Importfouten")
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel elementPanel--spaced" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "table",
                            {
                              staticClass: "item__list item__list--table",
                              attrs: { wrap: "" }
                            },
                            [
                              _c(
                                "tbody",
                                _vm._l(_vm.fileLogs, function(fileLog, index) {
                                  return _c(
                                    "tr",
                                    { key: index, staticClass: "list__item" },
                                    [
                                      _c("td", [
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "item__icon item__icon--error"
                                              },
                                              [_vm._v("error")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "item--bold" },
                                          [_vm._v(_vm._s(fileLog))]
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }